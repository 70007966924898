import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';

import {
  ROUTE_REPORT, OVERVIEW, STAGE_UNKNOWN,
  STAGE_IN_BUILD,
  STAGE_QA,
  STAGE_LAUNCH_PREP,
  STAGE_LAUNCHED,
  STAGE_DELETED,
  STAGE_REPLICATED,
} from '../../constants';
import MCKINSEY_SANS from '../../stylesheets/fonts';

import {
  MODERATE_DARK_GREY, MODERATE_LIGHT_GREY, STAGE_REPLICATED_BGCOLOR,
  STAGE_LAUNCH_PREP_BGCOLOR, STAGE_IN_BUILD_BGCOLOR, STAGE_DELETED_BGCOLOR, STAGE_UNKNOWN_BGCOLOR,
  STAGE_QA_BGCOLOR, STAGE_LAUNCHED_BGCOLOR, ACCORDION_GREY, BLACK,
} from '../../stylesheets/colors';
import LockIcon from '../../assets/icons/Lock.svg';

import StageBadge from '../StageBadge/StageBadge';

const decideBorderColor = ({ stage }) => ({
  [STAGE_UNKNOWN]: STAGE_UNKNOWN_BGCOLOR,
  [STAGE_IN_BUILD]: STAGE_IN_BUILD_BGCOLOR,
  [STAGE_QA]: STAGE_QA_BGCOLOR,
  [STAGE_LAUNCH_PREP]: STAGE_LAUNCH_PREP_BGCOLOR,
  [STAGE_LAUNCHED]: STAGE_LAUNCHED_BGCOLOR,
  [STAGE_DELETED]: STAGE_DELETED_BGCOLOR,
  [STAGE_REPLICATED]: STAGE_REPLICATED_BGCOLOR,
}[stage] || MODERATE_LIGHT_GREY);

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    flex: 1,
    userSelect: 'none',
    '-webkit-user-select': 'none',
    '-moz-select': 'none',
    '-ms-select': 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '16rem',
    flex: 1,
    border: ({ stage }) => `1px solid ${decideBorderColor({ stage })}`,
    fontFamily: theme.typography.fontFamily,
    overflow: 'hidden',
    borderRadius: '10px',
    boxShadow: 'none',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.2rem 1.5rem 0.9rem',
    flex: 1,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.62,
    color: MODERATE_DARK_GREY,
  },
  updatedByWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: '0.875rem',
    minHeight: '1.25rem',
    color: ACCORDION_GREY,
    fontWeight: 400,
    fontFamily: MCKINSEY_SANS,
  },
  configIdWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: '1.5rem',
    lineHeight: '20px',
    userSelect: 'text',
    marginBottom: '9px',
    fontFamily: MCKINSEY_SANS,
    color: BLACK,
    '-webkit-user-select': 'text',
    '-moz-select': 'text',
    '-ms-select': 'text',
  },
  lpIdWrapper: {
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '20px',
    fontFamily: MCKINSEY_SANS,
    color: ACCORDION_GREY,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    '& span': {
      fontWeight: '300',
    },
  },
  statusWrapper: {
    display: 'flex',
    borderRadius: '0px 0px 9px 9px',
    borderTop: `1px dashed ${MODERATE_LIGHT_GREY}`,
    padding: '9px 16px',
    gap: '10px',
    alignItems: 'center',
  },
  bottomWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  bottomLeftWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  stageWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '20px',
  },
  buildStatus: {
    color: ACCORDION_GREY,
    fontFamily: MCKINSEY_SANS,
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: '12px',
    '& span': {
      fontWeight: 400,
    },
  },
}));

const LandingPageCard = ({
  updatedBy,
  configId,
  programId,
  currentSearchText,
  isAcademyGo,
  isGsr,
  isSkills,
  cardData,
}) => {
  const {
    program_name, build_type, stage, is_locked,
  } = cardData;
  // eslint-disable-next-line react/prop-types
  const classes = useStyles({ stage });

  const updatedByText = `Last updated by: ${updatedBy}`;

  return (
    <Link
      className={classes.link}
      color="inherit"
      component={RouterLink}
      to={{ pathname: `/${ROUTE_REPORT}/${programId}/${OVERVIEW}`, state: { currentSearchText } }}
    >
      <Paper className={classes.wrapper}>
        <div className={classes.innerWrapper}>
          <div className={classes.bottomWrapper}>
            <div className={classes.bottomLeftWrapper}>
              <div className={classes.stageWrapper}>
                <StageBadge stage={stage} hideIcon position="landing_page" />
                {isAcademyGo && (
                <Box style={{ paddingLeft: '0.6rem' }}>
                  <StageBadge stage="ACADEMY_GO" />
                </Box>
                )}
                {isGsr && (
                <Box style={{ paddingLeft: '0.6rem' }}>
                  <StageBadge stage="LP_GSR" />
                </Box>
                )}
                {isSkills && (
                <Box style={{ paddingLeft: '0.6rem' }}>
                  <StageBadge stage="LP_SKILLS" />
                </Box>
                )}
              </div>
              {is_locked && (
                <div><img width={14} height={14} src={LockIcon} alt="lock" /></div>
              )}
            </div>
          </div>
          <div className={classes.infoWrapper}>
            <Typography className={classes.updatedByWrapper}>{updatedByText}</Typography>
          </div>
          <div className={classes.titleWrapper}>
            <Typography className={classes.configIdWrapper}>{configId}</Typography>
            <Typography title={program_name} className={classes.lpIdWrapper}>
              <span> LP Name: </span>
              {program_name}
            </Typography>
          </div>
        </div>
        <div className={classes.statusWrapper}>
          <Typography className={classes.buildStatus}>
            {' '}
            <span> Build Type: </span>
            {build_type || ' N/A'}
          </Typography>
        </div>
      </Paper>
    </Link>
  );
};

LandingPageCard.propTypes = {
  updatedBy: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  stage: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
  currentSearchText: PropTypes.string,
  isAcademyGo: PropTypes.bool.isRequired,
  isGsr: PropTypes.bool.isRequired,
  isSkills: PropTypes.bool.isRequired,
  cardData: PropTypes.object.isRequired,
};

LandingPageCard.defaultProps = {
  currentSearchText: '',
};

export default LandingPageCard;
