/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState, useContext,
  useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';

import { actions as midActions } from '@cs/mid-react-saga';

// import { LOGOUT } from '@nvt/mid-react/dist/store/constants';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import MyContext from '../../context';
import LandingPage from '../../components/LandingPage';
// eslint-disable-next-line import/no-cycle
import {
  setInLocalStorage,
} from '../../helpers/userHelper';
import {
  getEnvironment, getLtiToken, getFilterGroups,
} from './apis';
import {
  COURSE_OPS,
  COURSE_PROD,
  ENVIRONMENT_KEY,
  GROUPWORK_GROUPS, LTI_TOKEN,
  PROGRAM_CREATION,
  ROLE_OPS,
  ROUTE_UPLOAD,
  STAGE_IN_BUILD,
  STAGE_QA,
  STAGE_LAUNCH_PREP,
  STAGE_LAUNCHED,
  STAGE_UNKNOWN,
  STAGE_REPLICATED,
} from '../../constants';
import { GET_PROGRAMS, GET_FILTERED_PROGRAMS } from '../../store/constants';

const useStyles = makeStyles({
  alertSnackBar: {

  },
});

const defaultStageSelection = [
  STAGE_IN_BUILD,
  STAGE_QA,
  STAGE_LAUNCH_PREP,
  STAGE_LAUNCHED,
  STAGE_UNKNOWN,
  STAGE_REPLICATED,
];

const BasePage = () => {
  const { role, programType } = useContext(MyContext);
  const classes = useStyles();
  const history = useHistory();
  const bodyRef = useRef();
  const cardRef = useRef();

  const [searchText, setSearchText] = useState('');
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const [cardGap, setCardGap] = useState('10px');
  const [cardWidth, setCardWidth] = useState('330px');

  const lpData = useSelector((state) => state.learningPrograms);
  const { data: filterFormData } = useSelector(
    (state) => state.filterFormData,
  );
  const dispatch = useDispatch();

  const stages = defaultStageSelection.join(',');

  const {
    isLoading,
    programs,
    totalPrograms,
    hasMore,
    page,
    isFilteredData,
    isError,
  } = lpData;

  const loadInitialLPs = useCallback(() => {
    if (programType && pageSize) {
      dispatch({
        type: GET_PROGRAMS,
        payload: {
          programType,
          searchText,
          page: 0,
          page_size: pageSize,
          stages,
        },
      });
    }
  }, [searchText, programType, pageSize]);

  const updatePageSize = () => {
    if (bodyRef.current) {
      const container_obj = bodyRef.current.getBoundingClientRect();
      let card_width = 330;
      if (container_obj.width < 1300) {
        card_width = 285;
      }
      let no_of_card_per_row = parseInt(container_obj.width / card_width, 10);
      if (no_of_card_per_row > 6) {
        no_of_card_per_row = 6;
      }
      const page_size = Math.max(9, no_of_card_per_row * (no_of_card_per_row - 1));
      setPageSize(page_size);
      const remaining_width = container_obj.width - no_of_card_per_row * card_width;
      const gap = remaining_width / (no_of_card_per_row - 1);
      setCardWidth(`${card_width}px`);
      setCardGap(`${gap}px`);
    }
  };

  useEffect(() => {
    updatePageSize();
    window.addEventListener('resize', updatePageSize);
    return () => window.removeEventListener('resize', updatePageSize);
  }, []);

  useEffect(() => {
    if (Object.keys(filterFormData).length > 0) {
      dispatch({
        type: GET_FILTERED_PROGRAMS,
        payload: {
          filterParams: filterFormData,
          page: 1,
          page_size: pageSize,
        },
      });
    } else {
      loadInitialLPs();
    }
  }, [searchText, filterFormData, dispatch, programType, pageSize]);

  useEffect(() => {
    if (isError) {
      setSnackBarOpen(true);
    }
  }, [isError]);

  const onLoadMore = () => {
    if (isFilteredData) {
      dispatch({
        type: GET_FILTERED_PROGRAMS,
        payload: {
          filterParams: filterFormData,
          page,
          page_size: pageSize,
        },
      });
    } else {
      dispatch({
        type: GET_PROGRAMS,
        payload: {
          programType,
          searchText,
          page,
          stages,
          page_size: pageSize,
        },
      });
    }
  };

  const defaultProgramSubTypeLookup = {
    [COURSE_PROD]: PROGRAM_CREATION,
    [COURSE_OPS]: GROUPWORK_GROUPS,
  };

  const setLtiToken = async () => {
    const ltiToken = await getLtiToken();
    setInLocalStorage(LTI_TOKEN, ltiToken);
  };

  useEffect(() => {
    const getUserAndEnv = async () => {
      const env = await getEnvironment();
      setInLocalStorage(ENVIRONMENT_KEY, env);
    };
    getUserAndEnv();
    if (role !== ROLE_OPS) {
      setLtiToken();
    }
  }, []);

  const onCreateNewProgram = () => {
    history.push(`/${ROUTE_UPLOAD}/${defaultProgramSubTypeLookup[programType]}`);
  };

  const isDdROle = role === ROLE_OPS;

  return (
    <>
      <LandingPage
        cards={programs}
        onSearch={setSearchText}
        showCreateNewProgram={!isDdROle}
        onCreateNewProgram={onCreateNewProgram}
        onLoadMore={onLoadMore}
        hasMore={hasMore}
        isLoading={isLoading}
        programCount={totalPrograms}
        getFilterGroups={getFilterGroups}
        loadInitialLPs={loadInitialLPs}
        filterFormData={filterFormData}
        ref={{
          bodyRef,
          cardRef,
        }}
        cardGap={cardGap}
        cardWidth={cardWidth}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
      >
        <Alert
          className={classes.alertSnackBar}
          onClose={() => setSnackBarOpen(false)}
          severity="error"
        >
          Unable to connect to Docebo. Please try later.
        </Alert>
      </Snackbar>
    </>
  );
};

BasePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      platformType: PropTypes.string,
    }),
  }).isRequired,
  mid: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  mid: state.mid,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(midActions.logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasePage));
