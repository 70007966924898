import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useCheckActivityStatus from '../../../hooks/useCheckActivityStatus';
import ProgressStatusModal from '../../PlatformLevelUsers/ProgressStatusModal';
import { PROCESSING, QUEUED } from '../../../constants';

const UserMarkDeletion = ({
  transactionId, status, open, onClose, getReport,
  pollProgressValue, updatePollProgress, type,
}) => {
  const {
    preClose,
    progressData,
  } = useCheckActivityStatus(status,
    open, onClose, pollProgressValue, transactionId, updatePollProgress);

  const [progress, setProgess] = useState({});

  const SELECTED_ACTION = {
    user_deletion_flag: 'Mark',
    user_deletion_unflag: 'Unmark',
    bulk_user_creation: 'User_creation',
  };

  useEffect(() => {
    const fetchReportData = async () => {
      const response = await getReport(transactionId);
      setProgess(response);
    };
    if (status === PROCESSING || status === QUEUED) {
      setProgess(progressData);
    } else {
      fetchReportData();
    }
  }, [getReport, progressData, status, transactionId]);

  return (
    open && (
      <ProgressStatusModal
        open={open}
        onClose={preClose}
        progress={progress}
        action={SELECTED_ACTION[type]}
      />
    )
  );
};

UserMarkDeletion.propTypes = {
  transactionId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  pollProgressValue: PropTypes.bool.isRequired,
  updatePollProgress: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default UserMarkDeletion;
